import { ReactElement } from 'react';
import styles from './index.module.scss';

export interface Props {
    heading: string;
    icon?: ReactElement;
    background?: 'green' | 'white' | 'red';
    isOneLine?: boolean;
    children?: string | JSX.Element;
}

const backgroundToClass = {
    white: 'bgWhite',
    green: 'bgGreen',
    red: 'bgRed',
};

export const InfoBox = ({ heading, icon, background = 'white', isOneLine, children }: Props) => {
    return (
        <article
            className={`${styles[backgroundToClass[background] || backgroundToClass.white]} ${
                styles.infoBoxContainer
            }`}
        >
            {icon ? <span className={styles.icon}>{icon} </span> : null}
            <section
                className={`${styles.contentContainer} ${
                    isOneLine ? styles.oneLineContainer : ''
                } ${background === 'red' ? styles.cancellationContainer : ''}`}
            >
                <header className={styles.infoBoxHeading}>{heading}</header>
                <div className={styles.childrenStyle}>{children}</div>
            </section>
        </article>
    );
};
