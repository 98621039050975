import { Alert, AlertTitle } from '@mui/material';
import { Params } from 'api/contract';
import { Header } from 'components/NewHeader';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import toast from 'react-hot-toast';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Contract } from 'api/contract/types';
import { useContractQuery } from 'api/contract/useContractQuery';
import { ActionRequired, Important, OpenArrow } from 'assets/pandoraIcons';
import { MenuBar } from 'components/menu-bar';
import { useContractBillingTypesQuery } from 'features/contract/hooks/useContractBillingTypesQuery';
import { useContractThirdPartiesQuery } from 'features/contract/hooks/useContractThirdPartiesQuery';
import { useContractTypesQuery } from 'features/contract/hooks/useContractTypesQuery';
import { useUpdateContractMutation } from 'features/contract/hooks/useUpdateContractMutation';
import { useAuthenticatedUser } from 'hooks/useAuthenticatedUser';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import { ContractStatus } from 'types/enum/ContractStatus';
import { getCookieDomain } from 'utils/getCookieDomain';

import { Button, Spinner } from '@HometreeEngineering/component-library';
import Accordion from 'components/Accordion';
import { getThirdPartyIdType } from 'features/contract/utils/getThirdPartyIdType';
import { noEmailMatches } from 'features/contract/constants';

import AccountTable from './AccountTable';
import Notes from './CollapsableSections/Notes';
import PaymentOptions from './CollapsableSections/PaymentOptions';
import ContractDetailsFooter from './ContractDetailsFooter';
import CancelContractModal from './ContractModals/CancelContractModal';
import ChangeDatesModal from './ContractModals/ChangeDatesModal';
import WarningModal from './ContractModals/WarningModal';
import { ContractStatusInfo } from './ContractStatusInfo';
import ContractTable from './ContractTable';
import { HeaderButtons } from './HeaderButtons';
import Marketing from './Marketing';
import PlanAndPricing from './PlanAndPricing';
import PromocodeTable from './PromocodeTable';
import PropertyTable from './PropertyTable';
import TablesDisclaimer from './RelationsTables/TablesDisclaimer';
import { ThirdPartyId } from './ThirdPartyId';
import styles from './index.module.scss';

export const ContractDetails = () => {
    const [details, setDetails] = useState<Contract | undefined>(undefined);
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();
    const { data: typesData } = useContractTypesQuery();
    const { data: billingTypesData } = useContractBillingTypesQuery();
    const [showCancelContractModal, setShowCancelContractModal] = useState<boolean>(false);
    const [showChangeDateModal, setShowChangeDateModal] = useState<boolean>(false);
    const [isButtonsDisabled, setIsButtonsDisabled] = useState<boolean>(false);
    const [showActivateContractWarningModal, setShowActivateContractWarningModal] =
        useState<boolean>(false);
    const [paymentStatus, setPaymentStatus] = useState<{ id: number; message: '' }>();
    const navigate = useNavigate();
    const urlParams = useParams();
    const contractId = urlParams?.contractId ? parseInt(urlParams?.contractId, 10) : 0;
    const expandQuery =
        'property,account,package,pricebook,notes,promocode,referral,product_to_contract,renewals,claims,claimdb_claims,appliances';
    const params: Params = {
        log: true,
        paymentStatus: true,
        claimSource: 'BDX',
        expand: expandQuery,
    };

    const {
        isError: isContractError,
        isLoading: isLoadingContract,
        data: contractData,
    } = useContractQuery({ contractId, params });
    const { data: contractThirdParties } = useContractThirdPartiesQuery(contractId);
    const updateContractMutation = useUpdateContractMutation();

    // TODO create sections to display below areas
    const accordionTitles = useMemo(
        () => [
            'Payment Options',
            'Notes',
            'Marketing',
            // 'Preferred Management Solutions (PMS) Details',
        ],
        []
    );

    // TODO create sections to display below areas
    const displayOptions = useMemo(
        () =>
            new Map<string, JSX.Element>([
                ['Payment Options', <PaymentOptions data={details} />],
                [
                    'Notes',
                    <Notes
                        notes={details?.relations?.notes?.length ? details?.relations?.notes : []}
                        accountId={details?.account_id}
                        propertyId={details?.property_id}
                        contractId={details?.id}
                    />,
                ],
                ['Marketing', <Marketing data={details} />],
                // ['Preferred Management Solutions (PMS) Details', <div>Details about PMS</div>],
            ]),
        [details]
    );

    const thirdParties = useMemo(
        () =>
            contractThirdParties?.relations
                ?.filter(
                    // Filter out third party id types FE doesn't recognise
                    ({ thirdPartyType }) => !!getThirdPartyIdType(thirdPartyType)
                )
                .map((contractThirdParty) => contractThirdParty),
        [contractThirdParties]
    );

    const getPaymentStatus = (paymentStatusJson: string) => {
        try {
            const status = JSON.parse(paymentStatusJson);
            return status;
        } catch (e) {
            toast.error('Error processing payment status');
            return null;
        }
    };

    useEffect(() => {
        if (contractData && contractData.data?.[0]) {
            setDetails(contractData.data[0]);
        }
    }, [contractData]);

    useEffect(() => {
        if (details?.payment_status) {
            const parsedStatus = getPaymentStatus(details?.payment_status);
            if (parsedStatus) setPaymentStatus(parsedStatus);
        }
    }, [details?.payment_status]);

    if (isLoadingUser) {
        return <h3>Loading</h3>;
    }
    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    const handleReinstate = async () => {
        setIsButtonsDisabled(true);
        try {
            if (details && details.id) {
                const res = await updateContractMutation.mutateAsync({
                    id: details.id,
                    status: 4,
                    triggerConfirmationEmail: false,
                    cancellation_type: null,
                    cancellation_description: null,
                });
                toast.success(res.data?.message);
                setIsButtonsDisabled(false);
            }
            setIsButtonsDisabled(false);
        } catch (error) {
            toast.error((error as Error)?.message || 'Error reinstating contract');
            setIsButtonsDisabled(false);
        }
    };

    const handleActivate = async () => {
        if (details) {
            try {
                setIsButtonsDisabled(true);
                const date = new Date(Date.now());
                // Date string required to be in format "yyyy-mm-dd HH:mm:ss" for API and we don't have date-fns in this repo :(
                const dateString = `${date.toISOString().split('T')[0]} ${
                    date.toTimeString().split(' ')[0]
                }`;
                await updateContractMutation.mutateAsync({
                    id: details.id,
                    status:
                        new Date(details.start_date) <= new Date(Date.now())
                            ? ContractStatus.Live
                            : ContractStatus.Future,
                    triggerConfirmationEmail: true,
                    can_raise_claim: true,
                    date_created: dateString,
                });
                setShowActivateContractWarningModal(false);
                toast.success('Contract activated');
                setIsButtonsDisabled(false);
            } catch (error) {
                toast.error((error as Error)?.message || 'Error activating contract');
                setIsButtonsDisabled(false);
            }
        }
    };

    const paymentDisplayStyles = ['', styles.errorStatus, styles.unknown, styles.unknown];

    if (isContractError) {
        return (
            <>
                <MenuBar />
                <div className={styles.pageContainer}>
                    <div className={styles.container}>
                        <Alert severity="error">
                            <AlertTitle>Contract Details Error</AlertTitle>
                            Unable to load contract information for id {contractId}
                        </Alert>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <MenuBar />
            <div className={styles.pageContainer}>
                {isLoadingContract || !details ? (
                    <SkeletonLoader repeat={6} />
                ) : (
                    <>
                        <Header
                            text={
                                <div className={styles.headerWithIcon}>
                                    <Button
                                        onClick={() => navigate(-1)}
                                        customStyle={styles.arrowBtn}
                                    >
                                        <OpenArrow />
                                    </Button>
                                    <span>{details?.customer_facing_id || 'Loading'}</span>
                                </div>
                            }
                            buttons={
                                <HeaderButtons
                                    status={details.status}
                                    paymentStatus={paymentStatus}
                                    handleCancel={() =>
                                        setShowCancelContractModal(!showCancelContractModal)
                                    }
                                    handleReinstate={handleReinstate}
                                    isButtonsDisabled={isButtonsDisabled}
                                    handleActivate={() => setShowActivateContractWarningModal(true)}
                                    handleChangeDate={() => setShowChangeDateModal(true)}
                                />
                            }
                        />

                        <TablesDisclaimer
                            relations={details.relations}
                            contractPackage={details.package}
                        />
                        {details.is_read_only ? (
                            <div className={styles.readOnlyBanner}>
                                <ActionRequired />
                                OPENRENT ALERT: Do not edit or cancel anything on this page.
                                Customer must contact Openrent for any changes.
                                <strong>
                                    {' '}
                                    Claims can be submitted as long as the contract is Live.
                                </strong>
                            </div>
                        ) : null}
                        {noEmailMatches.some((pattern) => pattern.test(details.account.email)) ? (
                            <div className={styles.changeEmailBanner}>
                                <p>
                                    <Important />
                                    <span>Important:</span>
                                </p>
                                <p>
                                    Please{' '}
                                    <strong>
                                        ask for the customer’s email address and update it on the
                                        Account page
                                    </strong>{' '}
                                    for follow up communication and access to Hometree Portal.
                                </p>
                            </div>
                        ) : null}
                        {details.payment_status && !details.is_read_only ? (
                            <div
                                className={`${styles.paymentStatusContainer} ${
                                    paymentStatus?.id ? paymentDisplayStyles[paymentStatus?.id] : ''
                                }`}
                            >
                                <p>{paymentStatus?.message || 'NA'}</p>
                            </div>
                        ) : null}
                        <div className={styles.page}>
                            <div className={styles.titleSection}>
                                <h2>
                                    Contract Number {details.customer_facing_id}{' '}
                                    {updateContractMutation.isLoading ? <Spinner /> : null}
                                </h2>

                                <div className={styles.statusContainer}>
                                    {thirdParties?.map((contractThirdParty) => {
                                        return (
                                            <ThirdPartyId
                                                key={contractThirdParty.thirdPartyId}
                                                value={contractThirdParty.thirdPartyId}
                                                type={contractThirdParty.thirdPartyType}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={styles.statusContainer}>
                                <ContractStatusInfo details={details} />
                            </div>
                            <ContractTable
                                contractData={details}
                                typesData={typesData}
                                billingTypesData={billingTypesData}
                            />
                            <AccountTable account={details.account} />
                            <PropertyTable property={details.property} />
                            <PlanAndPricing
                                data={details}
                                typesData={typesData}
                                billingTypesData={billingTypesData}
                            />
                            <PromocodeTable
                                promocodeData={details.promocode}
                                contractId={details.id}
                            />
                        </div>
                        <Accordion
                            accordionTitles={accordionTitles}
                            displayOptions={displayOptions}
                            defaultOpen={details?.relations?.notes?.length ? 1 : null}
                        />
                        <ContractDetailsFooter data={details} />
                        {showCancelContractModal && (
                            <CancelContractModal
                                setIsOpen={setShowCancelContractModal}
                                isOpen={showCancelContractModal}
                                customerFacingId={details.customer_facing_id}
                                contractId={details.id}
                                isGCCustomer={!!details.go_cardless_subscription_id}
                                isCBCustomer={!!details.cb_subscription_id}
                            />
                        )}
                        {showChangeDateModal && (
                            <ChangeDatesModal
                                setIsOpen={setShowChangeDateModal}
                                isOpen={showChangeDateModal}
                                contractId={details.id}
                                customerFacingId={details.customer_facing_id}
                                startDate={details.start_date}
                                endDate={details.end_date}
                            />
                        )}
                    </>
                )}
                {showActivateContractWarningModal ? (
                    <WarningModal
                        isOpen={showActivateContractWarningModal}
                        setIsOpen={setShowActivateContractWarningModal}
                        warningMessage={
                            <span>
                                Are you sure you want to activate this contract to be{' '}
                                <strong>Live</strong>? Please note that this will send a welcome
                                email specifically for imported customers.
                            </span>
                        }
                        onCancel={() => setShowActivateContractWarningModal(false)}
                        onSave={handleActivate}
                        isSubmitting={isButtonsDisabled}
                    />
                ) : null}
            </div>
        </>
    );
};
